<template>
  <div class="ranking u-margin-top-medium" v-if="processed">
    <h3 class="c-title c-title--page-section">{{ processed.title }}</h3>
    <component :is="component" :exercises="processed.data.exercises" :items="processed.data.items"
         :rankingClass="processed.data.rankingClass" :tiny="processed.data.tiny" :showRank="showRank()"/>
    </div>
</template>

<script>
  import rankingLib from 'client/lib/ranking.js'

  import find from 'lodash/find'

  export default {
    name: 'ranking',
    components: {
      'single-table': require('./singleTable').default,
      'team-table': require('./teamTable').default,
      'awards-table': require('./awardsTable').default,
    },
    props: ['ranking', 'filter'],
    data: function () {
      return {
        processed: null,
        component: 'single-table',
        category: undefined,
        categoryRound: undefined,
        round: undefined,
        rankingClass: 'default',
      }
    },
    computed: {
      discipline: function () {
        return this.$store.state.eventDiscipline.discipline;
      },
    },
    watch: {
      ranking: {
        immediate: true,
        handler: function () {
          this.init()
        }
      },
      filter: {
        immediate: false,
        handler: function () {
          this.init()
        }
      }
    },
    methods: {
      init: function () {
        if (this.ranking) {
          console.log(this.ranking)
          const ranking = this.ranking
          const categoryId = ranking.categoryId
          console.log(categoryId)
          this.category = find(this.$store.state.categories.items, (item) => item.id === categoryId)
          console.log(this.category)

          this.categoryRound = find(this.category.rounds, item => item.roundId === ranking.roundId)
          this.round = find(this.$store.state.eventDiscipline.rounds, item => item.id === ranking.roundId)

          this.processed = rankingLib.preparePrint(ranking, this.category, this.round, this.discipline, this.filter)
          this.component = this.getComponent()
        }
      },
      getComponent: function() {
        let component = 'single-table'

        switch (this.processed.type) {
          case 'teamRanking':
            component = 'team-table'
            break
          case 'awards':
            component = 'awards-table'
            break
        }

        return component
      },
      showRank: function () {
        return this.processed.data.settings.showRank
      },
    }
  }
</script>

<style>
</style>
