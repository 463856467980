<template>
  <table ref="ranking" class="c-table js-table u-margin-top-medium">
    <thead>
    <tr>
      <th class="c-table__fixed-x-small-col c-table__first-col" >{{$t('bib')}}</th>
      <th class="c-table__name-row-result">{{$t('participant')}}</th>
      <th class="c-table__name-row-result">{{$t('club')}}</th>
      <th class="">
        <div class="c-table__fixed-small-col">{{ $t('award') }}</div>
      </th>
      <th class="c-table__empty-last-col"></th>
    </tr>
    </thead>
    <tbody v-for="(item, it_i) in items" :key="item.id">
      <tr :class="{'c-table--odd-row': (it_i % 2) === 0}">
        <td class="c-table__fixed-x-small-col c-table__first-col">
          {{ item.bib }}
        </td>
        <td class="c-table__name-row-result js-names">
          <template v-for="name in item.names">
            <span :key="item.id + name" class="c-text-bold">{{ name }}<br/></span>
          </template>
        </td>
        <td class="c-table__name-row-result js-names">
          {{ item.clubName }}<br/>
        </td>
        <td class="c-table__fixed-small-col c-exercise c-exercise__last-col">
          <span class="c-points c-total c-total--large">{{ item.award }}</span>
        </td>
        <td class="c-table__empty-last-col"></td>
      </tr>
    </tbody>
  </table>
</template>

<script>
import base from './baseTable.vue'

export default {
  name: 'awardsTable',
  extends: base,
}
</script>
