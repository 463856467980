<template>
  <td class="c-exercise u-padding-right-20px"
      :class="classes">
    <div class="c-wrapper js-wrapper ">
        <template v-if="icon">
          <div class="c-points-wrapper">
            <div class="c-icon">
              <img :src="'/images/' + icon + '.svg'" alt="dns">
            </div>
          </div>
          <div class="c-points c-total c-label">{{$t(icon)}}</div>
        </template>
        <template v-else>
          <div class="c-points-wrapper" v-if="exercise.summary">
            <div class="c-points" v-if="exercise.choice">
              <span class="c-points__key">{{exercise.choice}}</span>
              <span class="c-points__value"></span>
            </div>
            <div class="c-points" v-for="val in exercise.summary.values" :key="val.label">
              <span v-if="! tiny" class="c-points__key">{{val.label}}</span>
              <span class="c-points__value">{{val.value}}</span>
            </div>
          </div>
          <div class="c-points c-total">
            <span class="c-points__key">
              <span v-if="exercise.ranking" class="c-badge" :data-rank="exercise.ranking">
              </span>
            </span>
            <span class="c-points__value">{{ exercise.summary.total }}</span>
          </div>
        </template>
    </div>
  </td>
</template>

<script>
export default {
  name: 'rankingExerciseResult',
  props: [
    'exercise', 'rankingClass', 'tiny'
  ],
  data: function() {
    return {
      status: null,
      icon: null,
    };
  },
  computed: {
    classes: function() {
      let classes = [this.rankingClass]

      if (this.icon) {
        classes.push('c-status--not-finished');
      }

      return classes;
    },
  },
  watch: {
    exercise: {
      immediate: true,
      handler: 'init'
    },
  },
  methods: {
    init: function() {
      if (! this.exercise) {
        this.status = 'dns'
        this.icon = 'dns'
        return
      }
      let status = this.exercise.status

      switch (status) {
        case 'dns':
          this.icon = 'dns'
          break
        case 'scheduled':
          this.icon = 'planned'
          break
        case 'missing':
          this.icon = 'na'
          break
        default:
          this.icon = null
      }
      this.status = status
    },
  }
}

</script>

<style>
</style>
